
const state = {
    newStatus: ''
}
const getters = {
    newStatus: state => state.newStatus,
}


export default {
    namespaced: true,
    state,
    getters
}
