<!-- 可视化大屏 -->
<template>
  <div class="BdVisualScreen">
    <svg-icon class="screen_icon" icon-class="visualScreen" @click="visualScreenRoute"></svg-icon>
  </div>
</template>

<script>
import cookie from 'js-cookie'
export default {
  name: 'BdVisualScreen',
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    async visualScreenRoute() {
      window.open("#/LargeDataScreen")
    }
  }
}
</script>

<style lang="scss" scoped>
  .BdVisualScreen {
    margin-left: 20px;
  }
  .screen_icon {
    color: #FFFFFF;
    cursor: pointer;
  }
</style>