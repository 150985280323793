<!-- layouts 模式布局-平台模块 -->
<template>
  <div class="bd-layout-template-wrapper" :class="classObj">
    <component
      :is="'bd-layout-' + theme.layout"
      :collapse="collapse"
      :device="device"
      :fixed-header="theme.fixedHeader"
    />
    <el-backtop target="#app" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'LayoutsPage',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      theme: 'settings/theme',
      device: 'settings/device',
      collapse: 'settings/collapse',
    }),
    classObj() {
      return {
        mobile: this.device === 'mobile',
      }
    },
  },
  beforeMount() {
    this.oldLayout = this.theme.layout
    // window.addEventListener('resize', this.handleLayouts)
    // this.handleLayouts()
    this.updateTheme()
  },
  methods: {
    ...mapActions({
      toggleDevice: 'settings/toggleDevice',
      foldSideBar: 'settings/foldSideBar',
      openSideBar: 'settings/openSideBar',
      updateTheme: 'settings/updateTheme',
    }),
    handleLayouts() {
      const isMobile = document.body.getBoundingClientRect().width - 1 < 992
      console.log('[]', this.collapse, this.oldLayout)
      if (this.isMobile !== isMobile) {
        if (isMobile) {
          this.oldLayout = this.theme.layout
          this.foldSideBar()
        } else this.openSideBar()
        this.toggleDevice(isMobile ? 'mobile' : 'desktop')
        this.theme.layout = isMobile ? 'vertical' : this.oldLayout
        this.isMobile = isMobile
      }
    },
  }
}

</script>
<style lang='scss' scoped>
.bd-layout-template-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  [class*='bd-layout-'] {
    position: relative;

    :deep() {
      .bd-layout-header {
        box-shadow: $base-box-shadow;
      }
    }

    &.fixed {
      padding-top: $base-nav-height + $base-tabs-height;
    }

    &.fixed.no-tabs-bar {
      padding-top: $base-nav-height;
    }
  }

  :deep() {
      .fixed-header {
        // 使用 fixed 定位，会导致导航菜单闪烁，具体看笔记记录；
        // position: absolute;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: $base-z-index + 1;
        width: 100%;
      }

      .vab-main {
        position: relative;
        width: auto;
        min-height: 100%;
        margin-left: $base-left-menu-width;
      }

      &.is-collapse-main {
        margin-left: $base-left-menu-width-min;

        .fixed-header {
          left: $base-left-menu-width-min;
          width: calc(100% - #{$base-left-menu-width-min});
        }
      }

      // .app-main-container {
      //     >section {
      //       >[class*='-container'] {
      //         min-height: calc(100vh - #{$base-nav-height} - 40px) !important;
      //       }
      //     }
      //   }
    }
}
</style>
