/**
 * @description 主题配置：此配置修改后需要清理浏览器缓存！
 */

module.exports = {
  // common
  layout: 'common',
  // 菜单背景 none、vab-background
  background: 'vab-background',
  // 是否固定头部固定
  fixedHeader: true,
  // 是否开启进度条
  showProgressBar: false,
  // 布局时是否默认收起左侧菜单
//   collapse: true,
  foldSidebar: false
}
