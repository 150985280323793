import Vue from 'vue'
import { getToken, removeToken, setToken } from '@/utils/token'
import { resetRouter } from '@/router'
import { postLogin, getUserInfo, logout } from '@/api/login'
import { title, tokenName } from '@/config'
import { isString, isBoolean } from '@/utils/validate'
import cookie from 'js-cookie'

const state = () => ({
  token: getToken(),
  username: '管理员', // 用户名称
  realName: '', // 真实姓名
  avatar: require('../../assets/images/defaultAvatar.png'),
  // orgId: cookie.get('orgId'),
  organizeId: cookie.get('organizeId'),
  orgId: '1281521941815042049',
  loginInfor:{},
  userId:'',
  admin: false
})

const getters = {
  token: (state) => state.token,
  username: (state) => state.username,
  realName: (state) => state.realName,
  avatar: (state) => state.avatar,
  orgId: (state) => state.orgId,
  loginInfor:(state)=>state.loginInfor,
  userId:(state)=> state.userId,
  organizeId:(state)=> state.organizeId,
  admin:(state)=> state.admin
}

const mutations = {
  /**
   * @description 获取登录信息
   * @param {*} state
   * @param login
   */
  // setLoginInfor (state, login) {
  //   state.loginInfor = login
  // },
  /**
   * @description 设置token
   * @param {*} state
   * @param {*} token
   */
  setToken (state, token) {
    state.token = token
    setToken(token)
  },
   /**
   * @description 行政区划ID
   * @param {*} state
   * @param login
   */
  setOrgId (state, orgId) {
    state.orgId = orgId
    cookie.set('orgId', orgId)
  },
  /**
   * 获取组织架构ID
   * @param state
   * @param organizeId
   */
  setOrganizeId(state, organizeId){
    state.organizeId = organizeId
    cookie.set('organizeId', organizeId)
  },
  /**
   * @description 设置用户名
   * @param {*} state
   * @param {*} username
   */
  setUsername (state, username) {
    state.username = username
  },
  /**
   * @description 设置用户Id
   * @param {*} state
   * @param {*} userId
   */
  setUserId (state, userId) {
    state.userId = userId
  },
    /**
   * @description 设置用户真实姓名
   * @param {*} state
   * @param {*} realName
   */
     setRealName (state, realName) {
        state.realName = realName
      },
  /**
   * @description 设置头像
   * @param {*} state
   * @param {*} avatar
   */
  setAvatar (state, avatar) {
    state.avatar = avatar
  },
  /**
   * @description 设置是否管理员
   * @param {*} state
   * @param {*} admin
   */
  setAdmin (state, admin) {
    state.admin = admin
  }
}

const actions = {
  /**
   * @description 登录
   * @param {*} { commit }
   * @param {*} userInfo
   */
   async login({ commit }, userInfo) {
    const { data } = await postLogin(userInfo)

    if (data && data.code === 200) {
      window.localStorage.setItem('messageFlag','0')
      // commit('setLoginInfor',data.data)
      commit('setToken', data.data.token)
      // commit('setOrgId', data.data.orgId)

    } else {
      Vue.prototype.$baseMessage(data.msg, 'error')
      throw data.msg
    }
  },

  /**
   * @description 获取用户信息接口 在不明确框架底层逻辑的情况下，切勿对其进行修改，以免导致系统无法使用  ***！！！***
   * @param {*} { commit, dispatch, state }
   * @returns
  */
   async getUserInfo({ commit, dispatch }) {
    const { data: { data: { name, admin, orgIdList, realName, id ,headUrl, organizeIdList} } } = await getUserInfo()

    // commit('setOrgId', orgIdList[0])
    /**
     * 检验返回数据是否正常，无对应参数，将使用默认用户名,头像,Roles和Permissions
     * name {String} 用户名称
     * admin {Boolean}  是否为管理员
     */
    if ( (name && !isString(name)) || (admin && !isBoolean(admin)) || (realName && !isString(realName)) ) {
      const err = 'user 接口异常，请检查返回JSON格式是否正确'
      Vue.prototype.$baseMessage(err, 'error')
      throw err
    } else {
      
      // 如不使用username用户名,可删除以下代码
      if (name) commit('setUsername', name)
      if (realName) commit('setRealName', realName)
      
      if (id) commit('setUserId', id)
      // 如不使用avatar头像,可删除以下代码
      if (headUrl) commit('setAvatar', headUrl)
      if(organizeIdList) commit('setOrganizeId', organizeIdList[organizeIdList.length-1])
      if(admin) commit('setAdmin', admin)
    }
  },

  /**
   * @description 退出登录
   * @param {*} { dispatch }
   */
  async logout({ dispatch }) {
    // await logout()
    await dispatch('resetAll')
  },
  /**
   * @description 重置token、router、tabsBar等
   * @param {*} { commit, dispatch }
   */
  async resetAll({ commit, dispatch }) {
    commit('routes/setRoutes', [], { root: true })
    await dispatch('setToken', '')
    await dispatch('setOrgId', '')
    await dispatch('setOrganizeId', '')
    await dispatch('setAdmin', null ,{ root: true })
    await dispatch('tabs/delAllVisitedRoutes', null, { root: true })
    await dispatch('setAvatar', require('../../assets/images/defaultAvatar.png'))
    await resetRouter()
    removeToken()
    cookie.remove('orgId')
  },
  /**
   * @description 设置token
   * @param {*} { commit }
   * @param {*} token
   */
  setToken ({ commit }, token) {
    commit('setToken', token)
  },
   /**
   * @description 设置orgId
   * @param {*} { commit }
   * @param {*} orgId
   */
  setOrgId ({ commit }, orgId) {
    // commit('setOrgId', orgId)
  },
  /**
   * @description 设置头像
   * @param {*} { commit }
   * @param {*} avatar
   */
  setAvatar ({ commit }, avatar) {
    commit('setAvatar', avatar)
  }
}

export default { state, getters, mutations, actions }
