<template>
  <div id="SceneView"></div>
</template>

<script>
import Map from '@arcgis/core/Map'
import SceneView from '@arcgis/core/views/SceneView'
import Basemap from '@arcgis/core/Basemap'
import TileLayer from '@arcgis/core/layers/TileLayer'
export default {
  name: 'SceneView',
  mounted () {
    this.createMapView()
  },
  methods: {
    createMapView () {
      const chinabasemap = new TileLayer({
        url: 'http://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineCommunity/MapServer' // 中国彩色底图
      })

      const stamen = new Basemap({
        baseLayers: [chinabasemap]
      })

      const map = new Map({
        basemap: stamen,
        ground: 'world-elevation'
      })

      const view = new SceneView({
        map: map,
        container: 'SceneView',
        center: [108.953939, 34.345123],
        zoom: 6.5,
        heading: 0,
        tilt: 0
      })

      view.when(() => {
        view.goTo(
          {
            center: [108.953939, 34.345123],
            zoom: 6.5,
            heading: 0,
            tilt: 0
          },
          {
            speedFactor: 0.8,
            easing: 'linear'
          }
        ).catch((error) => {
          if (error.name !== 'AbortError') {
            console.error(error)
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/*二维地图组件 样式文件*/
#SceneView {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
</style>
