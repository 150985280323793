/**
 * @description 路由守卫，支持本地路由/动态路由
 */

import router from '@/router'
import store from '@/store'
import pageProgress from 'nprogress'
import 'nprogress/nprogress.css'
import getPageTitle from '@/utils/pageTitle'
import { toLoginRoute } from '@/utils/routes'

import { authentication, routesWhiteList } from '@/config'

pageProgress.configure({
  easing: 'ease',
  speed: 500,
  trickleSpeed: 200,
  showSpinner: false
})

router.beforeEach(async (to, from, next) => {
  const { showProgressBar } = store.getters['settings/theme']
  if (showProgressBar) pageProgress.start()

  const hasToken = store.getters['user/token']

  if (hasToken) {
    if (store.getters['routes/routes'].length) {
      if (to.path === '/login') {
        // next({ path: '/' })
        next({ path: '/guidePage' })
        if (showProgressBar) pageProgress.done()
      } else {
        if (to.path == '/') {
            next({ path: '/guidePage' })
        } else {
            next()
        }
        
      }
    } else {
      try {
        // 获取用户信息
        await store.dispatch('user/getUserInfo')
        // 根据路由模式获取路由
        await store.dispatch('routes/setRoutes', authentication)
        // next({ ...to, replace: true })
        if (to.path == '/') {
            next({ path: '/guidePage' })
        } else {
            next({ ...to, replace: true })
        }

      } catch (error) {
        console.error('错误拦截:', error)
        await store.dispatch('user/resetAll')
        next(toLoginRoute(to.path))
      }
    }
  } else {
    // 识别路由白名单
    if (routesWhiteList.includes(to.path)) {
      next()
    } else {
      next(toLoginRoute(to.path))
    }
  }
})

router.afterEach((to) => {
  document.title = getPageTitle(to.meta.title)
  if (pageProgress.status) pageProgress.done()
})
