import Vue from 'vue'
import { asyncRoutes, constantRoutes, resetRouter } from '@/router'
import { convertRouter, filterRoutes } from '@/utils/routes'
import { authentication, rolesControl } from '@/config'
import { isArray } from '@/utils/validate'
import { getMenuList } from '@/api/router'

const state = () => ({
  routes: [],
  activeName: ''
})

const getters = {
  routes: (state) => state.routes,
  activeName: (state) => state.activeName
}

const mutations = {
  /**
   * @description 多模式设置路由
   * @param {*} state
   * @param {*} routes
   */
  setRoutes (state, routes) {
    state.routes = routes
  },

  /**
   * @description 修改 activeName
   * @param {*} state
   * @param activeName 当前激活菜单
   */
  changeActiveName (state, activeName) {
    state.activeName = activeName
  }
}

let datafun = (data)=>{
  data.forEach(item =>{
    item.meta['id'] = item.id
    if(item.children.length!==0){
      datafun(item.children)
    }
  })
return data
}
const actions = {
  /**
   * @description 多模式设置路由
   * @param {*} { commit }
   * @param mode
   * @returns
   */
  async setRoutes ({ commit }, mode = 'none') {
    // 默认前端路由
    let routes = [...asyncRoutes]
    // 设置后端路由(不需要可以删除),intelligence(前端导出路由)和all(后端导出路由)
    if (authentication === 'all') {
      const result = await getMenuList()
      const list = datafun(result.data.data)
  
      list.unshift(
    //     {
    //     "id":"1522469281736998913",
    //     "pid":"0",
    //     "name":"overview",
    //     "path":"/home",
    //     "redirect": '/index',
    //     "component":"Layout",
    //     "meta":{
    //       "title":"总览",
    //       "icon":"home-3-line",
    //       "hidden":false,
    //       "noClosable":false,
    //       "levelHidden":true,
    //       "sort":0,
    //       "noKeepAlive":false,
    //       "tabHidden":false,
    //       "buttonList":[
    //       ],
    //       "id":"1522469281736998913"
    //     },
    //     "children":[
    //       {

    //         "id": "1522469735279419393",
    //         "pid": "1522469281736998913",
    //         "name": "Index",
    //         "path": "index",
    //         "component": "@/views/pages/projectMines/overview",
    //         "meta": {
    //           "title": "总览",
    //           "icon": "board",
    //           "hidden": false,
    //           "noClosable": true,
    //           "levelHidden": false,
    //           "sort": 0,
    //           "noKeepAlive": false,
    //           "tabHidden": false, 
    //           "buttonList": [
    //             3,
    //             5
    //           ],
    //           "id": "1522469735279419393"
    //         },
    //         "children": [],
    //         "whetherSystemMenu": false,
    //         "redirect": ""
    //       }
    //     ]
    //   }
      )
      
      let  routerFalg = list.find((item) => item.meta.title === '系统管理')
      if(routerFalg){
        if(routerFalg.children.find((i) => i.meta.title === '系统设置')){
          routerFalg.children[routerFalg.children.findIndex((s)=> s.meta.title === '系统设置')].children.push(
            {
            "id":"1534506551715815426",
            "pid":"1533715805271801857",
            "name":"PersonalCenter",
            "path":"personalCenter",
            "component":"@/views/pages/sysManage/systemSet/personalCenter",
            "meta":{
              "title":"个人中心",
              "icon":"user-2-line",
              "hidden":false,
              "noClosable":false,
              "levelHidden":false,
              "sort":5,
              "noKeepAlive":false,
              "tabHidden":false,
              "buttonList":[

              ],
              "id":"1534506551715815426"
            },
            "children":[

            ],
            "whetherSystemMenu":false,
            "redirect":""
          }
          )
        }else {
          routerFalg.children.push({
            "id":"1533715805271801857",
            "pid":"1531980955873959937",
            "name":"SystemSet",
            "path":"systemSet",
            "component":"",
            "meta":{
              "title":"系统设置",
              "icon":"board",
              "hidden":false,
              "noClosable":false,
              "levelHidden":false,
              "sort":1,
              "noKeepAlive":false,
              "tabHidden":false,
              "buttonList":[

              ],
              "id":"1533715805271801857"
            },
            "children":[
              {
                "id":"1534506551715815426",
                "pid":"1533715805271801857",
                "name":"PersonalCenter",
                "path":"personalCenter",
                "component":"@/views/pages/sysManage/systemSet/personalCenter",
                "meta":{
                  "title":"个人中心",
                  "icon":"user-2-line",
                  "hidden":false,
                  "noClosable":false,
                  "levelHidden":false,
                  "sort":5,
                  "noKeepAlive":false,
                  "tabHidden":false,
                  "buttonList":[

                  ],
                  "id":"1534506551715815426"
                },
                "children":[

                ],
                "whetherSystemMenu":false,
                "redirect":""
              }

            ],
            "whetherSystemMenu":false,
            "redirect":""
          })
        }
      }else {
        list.push( {
          "id":"1531980955873959937",
          "pid":"0",
          "name":"SysManage",
          "path":"/sysManage",
          "component":"Layout",
          "meta":{
            "title":"系统管理",
            "icon":"computer-line",
            "hidden":false,
            "noClosable":false,
            "levelHidden":false,
            "sort":10,
            "noKeepAlive":false,
            "tabHidden":false,
            "buttonList":[

            ],
            "id":"1531980955873959937"
          },
          "children":[
            {
              "id":"1533715805271801857",
              "pid":"1531980955873959937",
              "name":"SystemSet",
              "path":"systemSet",
              "component":"",
              "meta":{
                "title":"系统设置",
                "icon":"board",
                "hidden":false,
                "noClosable":false,
                "levelHidden":false,
                "sort":1,
                "noKeepAlive":false,
                "tabHidden":false,
                "buttonList":[

                ],
                "id":"1533715805271801857"
              },
              "children":[
                {
                  "id":"1534506551715815426",
                  "pid":"1533715805271801857",
                  "name":"PersonalCenter",
                  "path":"personalCenter",
                  "component":"@/views/pages/sysManage/systemSet/personalCenter",
                  "meta":{
                    "title":"个人中心",
                    "icon":"user-2-line",
                    "hidden":false,
                    "noClosable":false,
                    "levelHidden":false,
                    "sort":5,
                    "noKeepAlive":false,
                    "tabHidden":false,
                    "buttonList":[

                    ],
                    "id":"1534506551715815426"
                  },
                  "children":[

                  ],
                  "whetherSystemMenu":false,
                  "redirect":""
                }

              ],
              "whetherSystemMenu":false,
              "redirect":""
            }
          ],
          "whetherSystemMenu":false,
          "redirect":"/sysManage/systemSet/personalCenter"
        })
      }
      


      window.localStorage.setItem('menuList',JSON.stringify(list))

      if (!isArray(list)) {
        Vue.prototype.$baseMessage('路由格式返回有误！', 'error')
      }
      if (list[list.length - 1].path !== '*') {
        list.push({ path: '*', redirect: '/404', meta: { hidden: true } })
      }
      routes = convertRouter(list)
    }
    // 根据权限和 rolesControl 过滤路由
    const accessRoutes = filterRoutes([...constantRoutes, ...routes], rolesControl)
    // 设置菜单所需路由
    commit('setRoutes', JSON.parse(JSON.stringify(accessRoutes)))
    // 根据可访问路由重置Vue Router
    await resetRouter(accessRoutes)
  },
  /**
   * @description 修改 activeName
   * @param {*} { commit }
   * @param activeName 当前激活菜单
   */
   changeActiveName({ commit }, activeName) {
    commit('changeActiveName', activeName)
  },

}

export default { state, getters, mutations, actions }
