const state = {
  comparingLayer: [],
  legendData: []
}

const getters = {
  comparingLayer: (state) => state.comparingLayer,
  legendData: (state) => state.legendData
}

const mutations = {
  changeComparingLayer: (state, val) => {
    state.comparingLayer = val
  },
  changeLegendData: (state, val) => {
    state.legendData = val
  }
}

const actions = {
  changeComparingLayer ({ commit }, val) {
    commit('changeComparingLayer', val)
  },
  changeLegendData ({ commit }, val) {
    commit('changeLegendData', val)
  }
}

export default { state, getters, mutations, actions }
