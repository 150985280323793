<!--
 * @Author: 
 * @Date: 2022-05-05 09:59:39
 * @LastEditors: 
 * @LastEditTime: 2022-06-24 14:10:48
 * @FilePath: \BigData_Upgrades\src\components\DesignNumber\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
@describe: UI设计一套科技感数字 
@orthor: 吴江
@date: 2022/4/28
-->
<template>
  <div class="designNumber-container">
    <svg-icon v-for="(item, index) in NumberList"
              :key="index"
              :style="numObj.numStyle"
              class="svg-icon"
              :icon-class="item" />
  </div>
</template>

<script>
export default {
  name: 'DesignNumber',
  props: {
    numObj: {
      type: Object,
      default: function () {
        return {
          numString: '0',
          numStyle: {
            color: '#79BCFF',
            'font-size': '20px',
          },
        }
      },
      required: true, //必填
    },
  },
  data() {
    return {
      numString: '0',
      NumberList: [],
    }
  },
  watch: {
    'numObj.numString'(newStr, oldStr) {
      this.numString = newStr.toString()
      this.NumberList = [...this.numString]
      this.length = this.NumberList.length
      for (let i = 0; i < this.NumberList.length; i++) {
        if (this.NumberList[i] == '.') {
          this.NumberList[i] = 'point'
        }
      }
    },
  },
  mounted() {
    this.numString =
      this.numObj.numString == '' || this.numObj.numString == 0
        ? '0'
        : this.numObj.numString
    if (this.numObj.numString == 0 || this.numObj.numString == '') {
      this.NumberList = ['0']
    } else {
      this.NumberList = this.numString.toString()
      this.NumberList = [...this.numString]
    }

    this.length = this.NumberList.length
    for (let i = 0; i < this.NumberList.length; i++) {
      if (this.NumberList[i] == '.') {
        this.NumberList[i] = 'point'
      }
    }
  },
}
</script>

<style scoped lang="scss">
// 默认样式
.svg-icon {
  font-size: 18px;
  margin-left: -4px;
  color: #55efe8;
}
// .small-icon {
//   font-size: 4px !important;
//   margin: 0 4px 0 0;
// }
</style>
