<template>
  <el-row :gutter="20">

    <el-form  label-width="0" @submit.native.prevent>
      <el-col :span="18">
        <el-form-item label="">
          <el-input v-model="queryForm.title" clearable placeholder="请输入图标名称" style="width: 100%"></el-input>
        </el-form-item></el-col>
      <el-col :span="6">
        <el-form-item label-width="0">
          <el-button native-type="submit" type="primary" @click="queryData" style="width: 100%">搜索</el-button>
        </el-form-item>
      </el-col>
    </el-form>

    <el-col v-for="(item, index) in queryIcon" :key="index" :span="6">
      <el-card shadow="hover" @click.native="handleIcon(item)">
        <svg-icon :icon-class="item" class="icon-selector"/>
      </el-card>
    </el-col>
    <el-col :span="24">
      <el-pagination
        :background="background"
        :current-page="queryForm.pageNo"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-col>
  </el-row>
</template>

<script>
  import remixIcon from './remixIcon'

  export default {
    name: 'iconSelector',
    data() {
      return {
        layout: 'total, prev, next',
        total: 0,
        background: true,
        height: 0,
        queryIcon: [],
        queryForm: {
          pageNo: 1,
          pageSize: 16,
          title: '',
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        const { title, pageNo = 1, pageSize = 10 } = this.queryForm
        const mockList = remixIcon.filter((item) => !(title && item.indexOf(title) < 0))
        const list = mockList.filter((item, index) => index < pageSize * pageNo && index >= pageSize * (pageNo - 1))
        this.queryIcon = list
        this.total = mockList.length
      },
      handleIcon(item) {
        this.icon = item
        this.$emit('handle-icon', item)
      },
    },
  }
</script>

<style lang="scss">
  .icon-selector-popper {
      .el-card__body {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 40px;
        cursor: pointer;
        padding: 0;

        .icon-selector {
          font-size: 22px;
          text-align: center;
          vertical-align: middle;
          pointer-events: none;
          cursor: pointer;
        }
      }

      .el-pagination {
        margin: 0;
      }
    }
</style>
