import request from '@/utils/request'

/**
 * 登录接口【post】
 */
export function postLogin (data) {
  return request({
    url: '/rbac/v1/login',
    method: 'post',
    data
  })
};

/**  
 *  根据 token 获取用户信息 - 【get】
 */
 export function getUserInfo (params) {
  return request({
    url: '/rbac/v1/users/current/user',
    method: 'get',
    headers: {
        'Cache-Control': 'no-cache'
    },
    params
  })
};

//用户登录获取验证码
export function getMobileCodeByUserName (params) {
  return request({
    url: '/rbac/v1/getMobileCodeByUserName',
    method: 'get',
    params
  })
};

//手机登录获取验证码
export function getMobileCode (params) {
  return request({
    url: '/rbac/v1/getMobileCode',
    method: 'get',
    params
  })
};

// 退出系统
export function logout() {
  return request({
    url: '/logout',
    method: 'get',
  })
}

//手机登录获取验证码
export function GetVerification (params) {
  return request({
    url: '/rbac/v1/getForgetPasswordCode',
    method: 'get',
    params
  })
};
//重置密码接口
export function getupdateUserPassword (data) {
  return request({
    url: '/rbac/v1/updateUserPassword',
    method: 'post',
    data
  })
};
