/**
 * @description 公共组件全局引入
 */

import Vue from 'vue'

const requireComponents = require.context('../components', true, /\.vue$/)
requireComponents.keys().forEach((fileName) => {
  const componentConfig = requireComponents(fileName)
  const componentName = componentConfig.default.name
  Vue.component(componentName, componentConfig.default || componentConfig)
})
