<template>
  <div class="bdRefresh">
    <i class="el-icon-refresh" @click="refreshRoute"></i>
  </div>
</template>

<script>
  export default {
    name: 'bdRefresh',
    methods: {
      async refreshRoute() {
        this.$baseEventBus.$emit('refresh-router-page')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .bdRefresh {
    margin-left: 20px;
    margin-right: 35px;
    .el-icon-refresh {
      cursor: pointer;
    }
  }
</style>