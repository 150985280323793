<!--
@name:吴江
@orthor:提示框组件 tipsIcon:可不传，有默认图标class。 tipsContent：必传，提示框里面的内容。
@date:2022-04-19
-->
<template>
    <span class="tips">
    <i :class="tipsIcon" class="icon_tips"></i>
    <span class="tipsContent">{{tipsContent}}</span>
    </span>
</template>

<script>
export default {
  name: 'Tips',
  props: {
    tipsIcon: {
      type: String,
      default:'el-icon-question'
    },
    tipsContent: {
      type: String,
      default: function () {
        return ''
      }
    }
  },
  computed: {},
  data () {
    return {
    }
  },
  created () {},
  mounted () {},
  methods: {},
}
</script>

<style lang="scss" scoped>
// 提示框
.tips{
    position: relative;
    display: inline-block;
    cursor: pointer;
}
.icon_tips{
  font-size: 20px;
  width: 20px;
  height: 20px;
}
.tipsContent{
    display: none;
    min-width: 350px;
    max-width: 350px;
    position: absolute;
    left: 30px;
    top: 0px;
    z-index: 99;
    padding: 15px;
    border-radius: 6px;
    line-height: 1.8;
    font-size: 14px;
    color: #666666;
    text-align: justify;
    background-color: #fbfbfb;
    box-shadow: #e3e3e3 3px 3px 10px -3px;
}
.tips:hover {
  .icon_tips{
    opacity: .8;
  }
  .tipsContent{
    display: block ;
  }
}
</style>
