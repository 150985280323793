import request from '@/utils/request'

/*
@description: 系统管理 - 个人中心
@Author: mqq
@date: 2020-05-25 20:00
*/

export function getUsersCenter (params) {
  return request({
    url: '/rbac/v1/users/center/detail',
    method: 'get',
    headers:{
        'Cache-Control': 'no-cache'
    },
    params
  })
};
export function postUsersCenter (data) {
  return request({
    url: '/rbac/v1/users/center/update',
    method: 'post',
    data
  })
};
// 上传头像转化为url
export function uploadFile (data) {
  return request({
    url: '/zuul/cloudstorage/fileUpload',
    method: 'post',
    data
  })
};
// 上传头像
export function uploadHeadUrl (data) {
  return request({
    url: '/rbac/v1/users/uploadHeadUrl',
    method: 'post',
    data
  })
};