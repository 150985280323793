/**
 * @description 导出 vue/cli 的相关配置，如有修改需要重新启动项目
 */

module.exports = {
  // 开发以及部署时的URL
  publicPath: './',
  // 生产环境构建文件的目录名
  outputDir: 'dist',
  // 放置生成的静态资源 (js、css、img、fonts) 的 (相对于 outputDir 的) 目录。
  assetsDir: 'static',
  // 开发环境每次保存时是否输出为eslint编译警告
  lintOnSave: false,
  // 进行编译的依赖
  transpileDependencies: ['resize-detector'],
  // npm run build 时是否开启图片压缩，必须使用cnpm, 如无法使用cnpm，请配置false
  imageCompression: false,
}
