<template>
  <!--常规布局 -->
  <div
    class="vab-layout-common"
    :class="{fixed: fixedHeader, 'no-tabs-bar': !showTabs,}"
  >
    <div
      class="vab-layout-header"
      :class="{
        'fixed-header': fixedHeader,
      }"
    >
      <bd-header layout="common" />
      <div  v-if="routePath === '/guidePage' || routePath === '/newsMore' ? false : true ">
        <side-bar     layout="common"/>
      </div>
    </div>
    <div
      class="vab-main main-padding"
      :class="{
        'is-collapse-main': collapse,
      }"
      :style='{marginLeft: (routePath === "/guidePage" || routePath === "/newsMore") ? "0" : (collapse ? "64px" : "235px")}'
    >
      <app-main />
    </div>
  </div>
</template>

<script>
import { handleActivePath } from '@/utils/routes'
  export default {
    name: 'bdLayoutCommon',
    props: {
      collapse: {
        type: Boolean,
        default() {
          return false
        },
      },
      fixedHeader: {
        type: Boolean,
        default() {
          return true
        },
      },
      showTabs: {
        type: Boolean,
        default() {
          return true
        },
      },
      device: {
        type: String,
        default() {
          return 'desktop'
        },
      },
    },
    watch: {
    $route: {
      handler () {
        this.routePath = handleActivePath(this.$route, true)
      },
      immediate: true
    },
  },
    data() {
      return {
        routePath: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .vab-layout-common {
    .vab-main {
      padding-top: 70px;
      .fixed-header {
        left: $base-left-menu-width;
        width: $base-right-content-width;
      }
    }

    :deep() {
      .vab-tabs-content {
        width: calc(
          100% - 60px - #{$base-font-size-default} - #{$base-padding} - 2px
        ) !important;
      }

      .vab-header {
        .vab-main {
          width: 100%;
          margin: auto $base-margin;
        }
      }
    }
  }
</style>
