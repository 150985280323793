import Vue from 'vue'
import ElementUI from 'element-ui'
import '@/bd/styles/variables/element-variables.scss'
import 'element-ui/lib/theme-chalk/display.css'

import { Message } from "element-ui"





Vue.use(ElementUI, {
  size: 'small'
})

//定义一个新的Message方法，多传入一个offset参数
const $message = options => {
  return Message({
    ...options,
    offset: 60
  });
};

//重写方法,将offset写入options
['success', 'warning', 'info', 'error'].forEach(type => {
  $message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
        offset: 60
      };
    }
    options.type = type;
    return Message(options);
  };
});
//将$message挂载到this上
Vue.prototype.$message = $message;
//不加这行代码运行this.$message.closeAll时会报错
Vue.prototype.$message.closeAll = ElementUI.Message.closeAll;