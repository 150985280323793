/**
 * @description 导出网络配置
 */

////----------------------------------------------------------》》》》开发环境接口访问配置《《《《------------------------------------------------------------------------------////
/**
 * 开发环境 接口请求地址
 * @type {{
 * baseURL: string,     业务访问接口
 * webscoket: string,   即时通讯连接地址
 * cloudUrl: string,    云存储上传下载
 * aiPictureUrl: string AI检测  一张图  webscoket已读消息  一张图下载
 * }}
 */

// const requestAddrGather = {
//   baseURL: 'http://192.168.40.114:49000',
//   cloudUrl: 'http://192.168.40.114:49601',
//   webscoket: 'ws://192.168.40.203:9600',
//   aiPictureUrl: 'http://192.168.40.203:9600',
//   wsUrl:'wss://192.168.40.114:53111/room1',
//   iceUrl:'stun:106.12.254.111:3478',
// }

////----------------------------------------------------------》000》》》测试环境接口访问配置《《《《-------------------------------------------------------------------------------////
/**
 * 测试环境 接口请求地址
 * @type {{
 * baseURL: string,      业务访问接口
 * webscoket: string,    即时通讯连接地址
 * cloudUrl: string,     云存储上传下载
 * aiPictureUrl: string  AI检测  一张图  webscoket已读消息  一张图下载
 * }}
 */
// const requestAddrGather = {
//   baseURL: 'https://testshccig.beidouenv.com:20443',
//   cloudUrl: 'https://testshccig.beidouenv.com:20443',
//   webscoket: 'wss://testshccig.beidouenv.com:20443/wss/',
//   aiPictureUrl: 'https://testshccig.beidouenv.com:20443',
// //   iceUrl:'stun:106.12.254.111:3478',
//   // iceUrl:'stun:1.82.253.175:3478',
// //   wsUrl:'wss://testshccig.beidouenv.com:20443/room1'
// iceUrl:'stun:1.82.253.175:3478',
// wsUrl:'wss://www.shxcoaleco.com/room1'
//   //   wsUrl:'wss://shccig.beidouenv.com/room1'
// //   wsUrl:'ws://1.82.253.175:53111/room2'
// // wsUrl:'ws://192.168.40.114:57695/room1'
// }


////-------------------------------------------------------》》》》开发测试公共接口访问配置环境《《《《---------------------------------------------------------------------------////
/**
 * 开发测试公用环境： arcgis api和遥感处理工具
 * @type {{
 * uploadFileUrl: string,   云处理工具访问接口
 * toolAddressUrl: string,  天地图底图访问地址
 * tiandituUrl: string,     天地图底图图层服务负载
 * subDomainsUrl: string[]  arcgis工具处理完成之后下载地址
 * }}
 *
 */

// const publicAddrGather = {
//   toolAddressUrl: 'https://portal.shaanxieco.com',
//   tiandituUrl: 'https://{subDomain}.tianditu.gov.cn',
//   subDomainsUrl: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
//   uploadFileUrl: 'https://testshccig.beidouenv.com:20443',
//   DTSServerUrl:'dts.beidouenv.com:20488'
// }

////----------------------------------------------------------》》》》正式环境《《《《------------------------------------------------------------------------------////
/**
 *
 * 正式环境 接口访问地址
 *
 * @type {{
 * baseURL: string,      业务访问接口
 * webscoket: string,    即时通讯连接地址
 * cloudUrl: string,     云存储上传下载
 * aiPictureUrl: string  AI检测  一张图  webscoket已读消息  一张图下载
 * }}
 *
 * @type {{
 * uploadFileUrl: string,   云处理工具访问接口
 * toolAddressUrl: string,  天地图底图访问地址
 * tiandituUrl: string,     天地图底图图层服务负载
 * subDomainsUrl: string[]  arcgis工具处理完成之后下载地址
 * }}
 */
// const requestAddrGather = {
//   baseURL: 'https://www.shxcoaleco.com',
//   webscoket: 'wss://www.shxcoaleco.com/wss/',
//   cloudUrl: 'https://www.shxcoaleco.com',
//   aiPictureUrl: 'https://www.shxcoaleco.com',
//   iceUrl:'stun:1.82.253.175:3478',
//   wsUrl:'wss://www.shxcoaleco.com/room1'
// }

// const publicAddrGather = {
//   toolAddressUrl: 'https://portal.shaanxieco.com',
//   tiandituUrl: 'https://{subDomain}.tianditu.gov.cn',
//   subDomainsUrl: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
//   uploadFileUrl: 'https://www.shxcoaleco.com',
//   DTSServerUrl:'dts.beidouenv.com:20488'
// }


// module.exports = {
//   ...requestAddrGather,
//   ...publicAddrGather,
//   // 数据请求的接收方式
//   contentType: 'application/json;charset=UTF-8',
//   // 数据请求的最大时间 1个小时
//   requestTimeout: 3600000,
//   successCode: [200, 0, '200', '0'],
//   statusName: 'code',
//   messageName: 'msg'
// }

module.exports = {
	// base路径
	baseURL: `${process.env.VUE_APP_BASE_URL}`,
    webscoket: `${process.env.VUE_APP_WEB_SOCKET_URL}`,
    cloudUrl: `${process.env.VUE_APP_CLOUD_URL}`,
    aiPictureUrl: `${process.env.VUE_APP_API_PICTURE_URL}`,
    iceUrl: `${process.env.VUE_APP_ICE_URL}`,
    wsUrl: `${process.env.VUE_APP_WS_URL}`,

    toolAddressUrl: `${process.env.VUE_APP_TOOL_ADDRESS_URL}`,
    tiandituUrl: `${process.env.VUE_APP_TIAN_DI_TU_ADDRESS_URL}`,
    subDomainsUrl: `${process.env.VUE_APP_SUB_DOMAINS_URL}`,
    uploadFileUrl: `${process.env.VUE_APP_UPLOAD_FILE_URL}`,
    DTSServerUrl: `${process.env.VUE_APP_DTSS_SERVER_URL}`,

	// 配后端数据的接收方式 application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
	contentType: "application/json;charset=UTF-8",
	// 最长请求时间
	requestTimeout: 30*60*1000,
	// 操作正常code，支持String、Array、int多种类型
	successCode: [200, 0, "200", "0"],
	// 状态信息的字段名称
	messageName: "msg",
	// 数据状态的字段名称
	statusName: "code"
};
