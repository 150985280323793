/*
*
*
* */
const remixIcon = []
const req = require.context('@/icons/svg', true, /\.svg$/)
const requireAll = (requireContext) => {
  const a = requireContext.keys().map(requireContext)
  for (let i = 0; i < a.length; i++) {
    const icon = a[i].default.id.slice(5)
    remixIcon.push(icon)
  }
}
requireAll(req)

export default remixIcon
