<!--  -->
<template>
  <el-dropdown @command="handleCommand" @visible-change="visibleChange">
    <span class="avatar-dropdown">
      <el-avatar v-if="!resetAvatar" class="user-avatar" :src="avatar" >
        <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
      </el-avatar>
      <!-- <img :src="avatar" alt="" class="user-avatar"> -->
      <div class="user-name">
        <span class="hidden-xs-only">{{ username }}</span>
        <i :class="!active ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"></i>
      </div>
    </span>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item command="userCenter">
          <i class="el-icon-user"></i>
          个人中心
        </el-dropdown-item>
        <el-dropdown-item command="logout">
          <i class="el-icon-switch-button"></i>
          退出登录
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { toLoginRoute } from '@/utils/routes'
export default {
  name: 'BdAvatar',
  data () {
    return {
      active: false,
    //   需要重置头像组件,默认不重置。
    resetAvatar:false
    }
  },
  computed: {
    ...mapGetters({
      avatar: 'user/avatar',
      username: 'user/username',
    }),
  },
  watch:{
    avatar:function(newVal,oldVal){
        this.resetAvatar = true
        setTimeout(()=>{
            this.resetAvatar = false
            this.avatar = newVal
        },100)
        console.log("=========================", newVal)
    }
  },
  methods: {
    ...mapActions({
      _logout: 'user/logout',
    }),
    visibleChange (val) {
      this.active = val
    },
    handleCommand(command) {
      switch (command) {
        case 'userCenter':
          this.userCenter()
          break
        case 'logout':
          this.logout()
          break
      }
    },
    // 个人中心
    async userCenter () {
      this.$router.push('/sysManage/systemSet/personalCenter')
    },
    // 退出
    async logout () {
        this.$confirm("此操作将退出登录 , 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            closeOnClickModal: false,
            closeOnPressEscape: false,
        }).then(async () => {
            await this._logout()
            await this.$router.push(toLoginRoute(this.$route.fullPath))
        }).catch((e) => {
            console.log("退出操作异常")
        });
     
    }
  }
}

</script>
<style lang='scss' scoped>
.avatar-dropdown {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;

  .user-avatar {
    width: 40px;
    height: 40px;
    margin-left: 15px;
    cursor: pointer;
    border-radius: 50%;
  }

  .user-name {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    height: 40px;
    margin-left: 6px;
    line-height: 40px;
    color: #FFFFFF;
    cursor: pointer;

    [class*='ri-'] {
      margin-left: 0 !important;
    }
  }
}
</style>