<template>
    <div class="echarts" />
  </template>
  
  <script>
    import * as echarts from 'echarts'
    import debounce from 'lodash/debounce'
    import { addListener, removeListener } from 'resize-detector'
  
    const INIT_TRIGGERS = ['initOptions', 'autoResize']
    const REWATCH_TRIGGERS = ['manualUpdate', 'watchShallow']
  
    export default {
        name: 'PublicEcharts',
        props: {
            option: {
                type: Object,
                default: () => {},
            },
            initOptions: {
                type: Object,
                default: () => {},
            },
            autoResize: {
                type: Boolean,
                default: true,
            },
            watchShallow: {
                type: Boolean,
                default: false,
            },
            manualUpdate: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                lastArea: 0,
            }
        },
        created() {
            this.initOptionsWatcher()
            INIT_TRIGGERS.forEach((prop) => {
                this.$watch(
                    prop,
                    () => {
                    this.refresh()
                    },
                    { deep: true }
                )
            })
            REWATCH_TRIGGERS.forEach((prop) => {
                this.$watch(prop, () => {
                    this.initOptionsWatcher()
                    this.refresh()
                })
            })
        },
        mounted() {
            if (this.option) {
                this.init()
            }
        },
        activated() {
            if (this.autoResize) {
                this.chart && this.chart.resize()
            }
        },
        destroyed() {
            if (this.chart) {
                this.destroy()
            }
        },
        methods: {
            mergeOptions(option, notMerge, lazyUpdate) {
            if (this.manualUpdate) {
                this.manualOptions = option
            }
            if (!this.chart) {
                this.init(option)
            } else {
                this.delegateMethod('setOption', option, notMerge, lazyUpdate)
            }
            },
            resize(option) {
                this.delegateMethod('resize', option)
            },
            dispose() {
                this.delegateMethod('dispose')
            },
            delegateMethod(name, ...args) {
                if (!this.chart) {
                    this.init()
                }
                return this.chart[name](...args)
            },
            delegateGet(methodName) {
                if (!this.chart) {
                    this.init()
                }
                return this.chart[methodName]()
            },
            getArea() {
                return this.$el.offsetWidth * this.$el.offsetHeight
            },
            init(option) {
                if (this.chart) {
                    return
                }
                const chart = echarts.init(this.$el, null, this.initOptions)
                chart.setOption(option || this.manualOptions || this.option || {}, true)
                Object.keys(this.$listeners).forEach((event) => {
                    const handler = this.$listeners[event]
                    if (event.indexOf('zr:') === 0) {
                    chart.getZr().on(event.slice(3), handler)
                    } else {
                    chart.on(event, handler)
                    }
                })
                if (this.autoResize) {
                    this.lastArea = this.getArea()
                    this.__resizeHandler = debounce(
                        () => {
                            if (this.lastArea === 0) {
                                this.mergeOptions({}, true)
                                this.resize()
                                this.mergeOptions(this.option || this.manualOptions || {}, true)
                            } else {
                                this.resize()
                            }
                            this.lastArea = this.getArea()
                        },
                        100,
                        { leading: true }
                    )
                    addListener(this.$el, this.__resizeHandler)
                }
                Object.defineProperties(this, {
                    width: {
                        configurable: true,
                        get: () => {
                            return this.delegateGet('getWidth')
                        },
                    },
                    height: {
                        configurable: true,
                        get: () => {
                            return this.delegateGet('getHeight')
                        },
                    },
                    isDisposed: {
                        configurable: true,
                        get: () => {
                            return !!this.delegateGet('isDisposed')
                        },
                    },
                    computedOptions: {
                        configurable: true,
                        get: () => {
                            return this.delegateGet('getOption')
                        },
                    },
                })
                this.chart = chart
                },
                initOptionsWatcher() {
                if (this.__unwatchOptions) {
                    this.__unwatchOptions()
                    this.__unwatchOptions = null
                }
                if (!this.manualUpdate) {
                    this.__unwatchOptions = this.$watch(
                    'option',
                    (val, oldVal) => {
                        if (!this.chart && val) {
                        this.init()
                        } else {
                        this.chart.setOption(val, val !== oldVal)
                        }
                    },
                    { deep: !this.watchShallow }
                    )
                }
            },
            destroy() {
                if (this.autoResize) {
                    removeListener(this.$el, this.__resizeHandler)
                }
                this.dispose()
                this.chart = null
            },
            refresh() {
                if (this.chart) {
                    this.destroy()
                    this.init()
                }
            },
        }
    }
  </script>
<style>
    .echarts {
        width: 100%;
    }
</style>
  