/*
 * @Author: 
 * @Date: 2022-06-07 16:27:24
 * @LastEditors: 
 * @LastEditTime: 2022-06-07 18:13:36
 * @FilePath: \BigData_Upgrades\src\store\modules\system.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import { getToken, removeToken, setToken } from '@/utils/token'
import { resetRouter } from '@/router'
import { postLogin, logout } from '@/api/login'
import { title, tokenName } from '@/config'
import cookie from 'js-cookie'
import Cookies from 'js-cookie'

const state = () => ({
    stepIndex: -1,
})

const getters = {
    stepIndex: (state) => state.stepIndex
}

const mutations = {
  setStepIndex (state, val) {
    state.stepIndex = val
    setStepIndex(stepIndex)
  },

}

const actions = {
  /**
   * @description 设置token
   * @param {*} { commit }
   * @param {*} setStepIndex
   */
   setStepIndex ({ commit }, stepIndex) {
    commit('setStepIndex', stepIndex)
  },
}

export default { state, getters, mutations, actions }
