/**
 * @description 配置：vue/cli配置|通用配置|主题配置|网络配置
 */

const cli = require('./modules/cli.config')
const setting = require('./modules/setting.config')
const network = require('./modules/net.config')
const theme = require('./modules/theme.config')

module.exports = {
  ...cli,
  ...setting,
  ...network,
  ...theme
}
