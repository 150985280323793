<template>
    <div class="app-main-container">
      <section>
        <transition mode="out-in" name="fade-transform">
          <keep-alive :include="keepAliveNameList" :max="keepAliveMaxNum">
            <router-view :key="routerKey" />
          </keep-alive>
        </transition>
      </section>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { keepAliveMaxNum } from '@/config'
import pageProgress from 'nprogress'
import { handleActivePath } from '@/utils/routes'

export default {
  name: 'AppMain',
  data () {
    return {
      routerKey: null,
      keepAliveMaxNum,
      keepAliveNameList: [],
      dynamicNewTab: false
    }
  },
  computed: {
    ...mapGetters({
      theme: 'settings/theme',
      visitedRoutes: 'tabs/visitedRoutes'
    })
  },
  watch: {
    $route: {
      handler () {
        this.routerKey = handleActivePath(this.$route, true)
      },
      immediate: true
    },
    visitedRoutes: {
      handler () {
        this.updateKeepAliveNameList()
      }
    }
  },
  created () {
    this.updateKeepAliveNameList()
    this.$baseEventBus.$on(
      'refresh-router-page',
      (refreshRouteName = this.$route.name) => {
        if (this.theme.showProgressBar) pageProgress.start()
        const cacheActivePath = this.routerKey
        this.routerKey = null
        this.updateKeepAliveNameList(refreshRouteName)
        this.$nextTick(() => {
          this.routerKey = cacheActivePath
          this.updateKeepAliveNameList()
        })
        setTimeout(() => {
          if (this.theme.showProgressBar) pageProgress.done()
        }, 200)
      }
    )
  },
  methods: {
    updateKeepAliveNameList (refreshRouteName = null) {
      // this.keepAliveNameList = this.visitedRoutes
      //   .filter(
      //     (item) => !item.meta.noKeepAlive && item.name !== refreshRouteName
      //   )
      //   .map((item) => item.name)

      console.log('this.visitedRoutes', this.visitedRoutes)

      this.keepAliveNameList = [
          ...new Set(
            this.visitedRoutes
              .filter(
                (item) =>
                  !item.meta.noKeepAlive && item.name !== refreshRouteName
              )
              .flatMap((item) => item.matched)
          )
        ]

        console.log('this.keepAliveNameList', this.keepAliveNameList)
    }
  }
}
</script>

<style lang="scss" scoped>
    // .app-main-container {
    //     position: relative;
    //     width: 100%;
    //     height: 100%;
    //     overflow: hidden;

    //     .vab-keel {
    //         margin: $base-padding;
    //     }

    //     .app-main-height {
    //         // min-height: $base-app-main-height;
    //         height: 100%;
    //     }
    // }
</style>
