<!--  -->
<template>
  <div class="collapse" v-if="routePath === '/guidePage' || routePath === '/newsMore' ? false : true " >
    <i 
      class="coll-fold" 
      :class="collapse ? 'el-icon-s-fold' : 'el-icon-menu'" 
      @click="toggleCollapse"
    />
  </div>
</template>

<script>
import { handleActivePath } from '@/utils/routes'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Collapse',
  data () {
    return {
        routePath:''
    }
  },
  computed: {
    ...mapGetters({
      collapse: 'settings/collapse',
    }),
  },
  watch: {
    $route: {
      handler () {
        this.routePath = handleActivePath(this.$route, true)
      },
      immediate: true
    },
  },
  methods: {
    ...mapActions({
      toggleCollapse: 'settings/toggleCollapse',
    }),
  },
}
</script>
<style lang="scss" scoped>
  .collapse {
    margin-left: 20px;
  }
  .coll-fold {
    cursor: pointer;
  }
</style>