/**
 * 上传文件组件
 */

<template>
    <el-upload
        class="upload-demo"
        ref="uploader"
        style="width:100%;height:100%"
        :headers="{
            token: TOKEN,
        }"
        :data="customData"
        :action="uploadInfo.action"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :before-upload="beforeUpload"
        :on-success="uploadSuccess"
        :on-error="uploadError"
        :on-exceed="handleExceed"
        :file-list="fileList"
        :multiple="multiple"
        :limit="limit"
        :show-file-list="showFileList"
        :on-progress="handleProgress"
    >

        <slot>
            <!-- <span style="color: red;">如果父组件没有插入内容，我这样可以设置默认的显示内容</span> -->
             <div v-if="customImg != ''? true : false" style="width:22px;height:22px;display:flex;align-items: center;justify-content: center;" v-loadind="uploadInfo.fileLoading">
                <img :src="require('../../assets/'+customImg)" style="width:100%" alt="上传图标">
            </div>
            <el-button v-else  size="small" type="primary" :style="customStyle">
                <i :class="iconClass == '' ? 'iconNone' : iconClass"></i>
                {{ uploadTxt }}
            </el-button>
            <div slot="tip" class="el-upload__tip" :style="'color:'+tipColor">
                {{ uploadInfo.tip == "" ? "" : uploadInfo.tip }}
            </div>
        </slot>
       
    </el-upload>
</template>

<script>
export default {
  name: 'uploadInfo',
  props: {
    uploadInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {},
  data () {
    return {
      TOKEN: this.$store.state.user.token,
      customData: {}, // 上传时附带的额外参数
      uploaded: 0,
      fileList: [],
      fileTypeArr: [], // 整合允许上传文件类型
      httpRequest: null,

      limit: null, // 限制上传最大数量
      multiple: true, // 是否支持多选文件上传
      showFileList: true, // 是否显示已上传文件列表
      // UI表现
      customStyle: '', // 自定义按钮样式
      iconClass: 'el-icon-upload2', // 上传图标（字体图标）
      customImg: '', // 上传图标（图片形式）
      uploadTxt: '上传', // 上传文字
      tip: '', // 提示文字
      tipColor: '#606266'
    }
  },
  filters: {},
  created () {},
  mounted () {
    // element上传相关配置
    if (this.uploadInfo.limit !== undefined) {
      this.limit = parseInt(this.uploadInfo.limit)
    }
    if (this.uploadInfo.multiple !== undefined) {
      this.multiple = this.uploadInfo.multiple
    }
    if (this.uploadInfo.showFileList !== undefined) {
      this.showFileList = this.uploadInfo.showFileList
    }
    if (this.uploadInfo.data !== undefined) {
      this.customData = this.uploadInfo.data
    }
    if (this.uploadInfo.fileList !== undefined) {
      this.fileList = this.uploadInfo.fileList
    }
    if (this.uploadInfo.fileType !== undefined) {
      this.fileTypeArr = this.uploadInfo.fileType
    } else {
      this.fileTypeArr = []
    }
    // 关于UI表现参数
    if (this.uploadInfo.customStyle !== undefined) {
      this.customStyle = this.uploadInfo.customStyle
    }
    if (this.uploadInfo.iconClass !== undefined) {
      this.iconClass = this.uploadInfo.iconClass
    }
    if (this.uploadInfo.uploadTxt !== undefined) {
      this.uploadTxt = this.uploadInfo.uploadTxt
    }
    if (this.uploadInfo.customImg !== undefined) {
      console.log(7777)
      this.customImg = this.uploadInfo.customImg
    }
    if (this.uploadInfo.tip !== undefined) {
      this.tip = this.uploadInfo.tip
    }
    if (this.uploadInfo.tipColor !== undefined) {
      this.tipColor = this.uploadInfo.tipColor
    }
  },
  methods: {
    // 点击文件列表中已上传的文件时的钩子
    handlePreview (file) {
      console.log('点击文件列表中已上传的文件时的钩子', JSON.stringify(file))
    },
    // 文件列表移除文件时的钩子
    handleRemove (file, fileList) {
      console.log('文件列表移除文件', JSON.stringify(file))
      this.$emit('listenHandleRemove', file)
    },
    // 文件超出个数限制时的钩子
    handleExceed (files, fileList) {
      // this.$message.warning(`当前限制选择${this.uploadInfo.limit}个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
      // )
      this.$message.warning(`文件已存在，如需更改，单击文件后面的删除按钮，删除后重新上传。`
      )
    },
    // 文件上传时
    handleProgress(event, file, fileList){
        // console.log(event, file, fileList)
    },
    beforeUpload (file) {
        // 优化上传文件格式涉及大小写
        const fileType = file.name.split('.')[file.name.split('.').length - 1].toLowerCase();
        let newArr = []
        this.fileTypeArr.forEach((item)=>{
            item = item.toLowerCase()
            newArr.push(item)
        })
        this.fileTypeArr = newArr

      if (this.fileTypeArr !== undefined) {
        if (this.fileTypeArr.length > 0) {

          const isContains = this.fileTypeArr.includes(fileType)
          const fileNamesLength = file.name.split('.')[0].length
          if (!isContains) {
            const doms = document.getElementsByClassName('el-message')[0]
            if (doms === undefined) {
              this.$message.warning('上传文件类型不支持，请重新选择')
            }
            return false
          }
          // 限制上传文件名称字符
          if (fileNamesLength > 100) {
            const doms = document.getElementsByClassName('el-message')[0]
            if (doms === undefined) {
              this.$message.warning('文件名称应小于100！')
            }
            return false
          }
        }
      }
      
      this.$emit('listenBeforeUpload', file)
    },
    // 文件上传成功回调
    uploadSuccess (response, file, fileList) {
      if (response.success === 1) {
        console.log('上传成功')
      } else {
        this.$message.warning(response.msg)
        const uid = file.uid // 关键作用代码，去除文件列表失败文件
        const idx = this.$refs.uploader.uploader.findIndex((item) => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
        this.$refs.uploader.uploader.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
        console.error(response)
      }
      this.$emit('listenUploadSuccess', file)
      // return fileList;
    },
    // 删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除。
    beforeRemove (file) {
      this.$emit('listenBeforeRemove', file)
      // this.$confirm(`确定移除 ${file.name}？`);
      // return
    },
    uploadError (err, file, fileList) {
      this.$message.warning(err)
      this.$loading().close()
      this.$emit('listenUploadError', err, file, fileList)
      return err
    },
    // 取消请求
    abort(file){
        file ? this.$refs.uploader.abort(file) : this.$refs.uploader.abort()
        // this.$refs.uploader.abort()
    }
  },
  beforeUpdate () {},
  beforeDestroy () {}
}
</script>

<style scoped>
    .iconNone{
        display: none;
    }
</style>
