/**
 * AI检测接口
 **/
import request from '@/utils/request'
import { aiPictureUrl } from '@/config/modules/net.config'


//图层数据查询
export function infoStatus (params) {
  return request({
    url: `${aiPictureUrl}/biz/deeplearntask/viewAnalysis`,
    method: 'get',
    params
  })
}
