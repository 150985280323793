
import request from '@/utils/request'
import {cloudUrl} from '@/config'
// 分页查询
export function getUploadImage (data) {
  return request({
    url: '/zuul/cloudstorage/upload',
    method: 'post',
    data,
  })
}
