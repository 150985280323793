<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  watch:{
    $route(to, from){
      // console.log('路由变化了')
      console.log('当前页面路由：' , to);
      // console.log('上一个路由：' , from);
      window.localStorage.setItem("meuPath",to.meta.id)
    }
  },
  mounted() {
    console.log('process.env', process.env)
    console.log('`${process.env----.BASE_URL}`', process.env.VUE_APP_WEB_SOCKET_URL)
  }
}

</script>

<style lang="scss">

</style>
