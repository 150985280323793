<!-- 上传图片 -->
<template>
  <div id="CropperImage" class="PictureCropper-container">
    <slot>
      <!-- <el-button
        type="primary"
        plain
        size="small"
        icon="el-icon-picture"
        @click="uploadImage"
        >上传图片
      </el-button> -->
    </slot>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :title="tailorInfo.title"
      :visible.sync="dialogVisible"
      width="860px"
      :before-close="handleClose"
    >
      <div class="cropper_box">
        <div>
          <div class="optionBtn">
            <label class="btn btnColor" for="uploads">选择图片</label>
            <input
              ref="upload"
              type="file"
              id="uploads"
              style="position: absolute; clip: rect(0 0 0 0)"
              accept=".png,.jpg,.jpeg"
              @change="uploadImg($event)"
            />
          </div>
          <div class="img-format">仅支持png,jpg,jpeg格式且大小限制300KB内。</div>
        </div>
        <div class="cut_box">
          <div class="cut">
            <VueCropper
              ref="cropper"
              :img="option.img"
              :output-size="option.size"
              :output-type="option.outputType"
              :info="option.info"
              :canScale="option.canScale"
              :autoCrop="option.autoCrop"
              :autoCropWidth="option.autoCropWidth"
              :autoCropHeight="option.autoCropHeight"
              :fixed="option.fixed"
              :fixedNumber="option.fixedNumber"
              :full="option.full"
              :fixedBox="option.fixedBox"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :original="option.original"
              :centerBox="option.centerBox"
              :height="option.height"
              :infoTrue="option.infoTrue"
              :maxImgSize="option.maxImgSize"
              :enlarge="option.enlarge"
              :mode="option.mode"
              @real-time="realTime"
            ></VueCropper>
            <div class="functionButton">
              <div>
                <el-button
                  type="primary"
                  size="small"
                  icon="el-icon-arrow-left"
                  @click="rotateLeft"
                  class="btn"
                  >向左旋转</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click="rotateRight"
                  class="btn"
                  >向右旋转<i class="el-icon-arrow-right el-icon--right"></i
                ></el-button>
              </div>
              <el-button
                style="margin-right: 0"
                type="warning"
                icon="el-icon-refresh-right"
                @click="refresh"
                class="btn"
                >复位</el-button
              >
            </div>
          </div>
          <div
            class="show-preview"
            v-if="previews.url"
            :style="{
              width: previews.w + 'px',
              height: previews.h + 'px',
              zoom: 240 / previews.h,
              zoom: 240 / previews.w,
              overflow: 'hidden',
              'border-radius': tailorInfo.radius,
            }"
          >
            <div :style="previews.div" style="background-color: #000000">
              <img
                :src="previews.url"
                :style="previews.img"
              />
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getpicture()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import { baseURL } from "@/config";
import { uploadFile } from '@/api/personalCenter'
export default {
  name: "CropperImage",
  components: {
    VueCropper,
  },
  props: {
    tailorInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    tailorInfo: {
      immediate: true,
      async handler(newUrl, oldUrl) {
        this.uploadUrl = newUrl.uploadImgUrl;
        this.tailorInfo = newUrl;
        this.monitoringCutting();
      },
    },
  },
  data() {
    return {
      actionUrl: `${baseURL}/zuul/cloudstorage/fileUpload`, //上传服务地址
      dialogVisible: false,
      imageUrl: '',
      option: {
        img: "", // 裁剪图片的地址
        outputSize: 1, // 裁剪生成图片的质量(可选0.1 - 1)
        outputType: "png", // 裁剪生成图片的格式（jpeg || png || webp）
        info: true, // 图片大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 300, // 默认生成截图框宽度
        autoCropHeight: 300, // 默认生成截图框高度
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // false按原比例裁切图片，不失真
        fixedBox: false, // 固定截图框大小，不允许改变
        canMove: true, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        height: true, // 是否按照设备的dpr 输出等比例图片
        infoTrue: true, // true为展示真实输出图片宽高，false展示看到的截图框宽高
        // maxImgSize: 1200, // 限制图片最大宽度和高度
        enlarge: 1, // 图片根据截图框输出比例倍数
        mode: "cover", // 图片默认渲染方式
      },
      uploadUrl: "",
      previewStyle: null,
      previews: {
        w: 240,
        h: 240,
      },
      zoom: 0,
    };
  },
  mounted() {
    this.monitoringCutting();
  },
  methods: {
    // 监听裁剪参数
    monitoringCutting() {
      // element上传相关配置
      if (this.tailorInfo !== undefined) {
        if (JSON.stringify(this.tailorInfo) === "{}") {
          this.option.autoCropWidth = 360; // 默认生成截图框宽度
          this.option.autoCropHeight = 222; // 默认生成截图框高度
        } else {
          if (
            this.tailorInfo.width === undefined ||
            this.tailorInfo.height === undefined
          ) {
            this.option.autoCropWidth = 360; // 默认生成截图框宽度
            this.option.autoCropHeight = 222; // 默认生成截图框高度
          } else {
            if (this.tailorInfo.width / this.tailorInfo.height === 1) {
              this.option.autoCropWidth = 360; // 默认生成截图框宽度
              this.option.autoCropHeight = 360; // 默认生成截图框高度
            } else {
              this.option.fixedNumber = [];
              this.option.fixedNumber.push(this.tailorInfo.width);
              this.option.fixedNumber.push(this.tailorInfo.height);
            }
          }
        }
      }
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
    },

    // 向左边旋转90度
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右边旋转90度
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 重置所有
    refresh() {
      this.$refs.cropper.reload();
      this.$refs.cropper.rotateClear();
    },
    // 上传图片
    uploadImage(url) {
      // window.URL.revokeObjectURL(this.option.img);
      this.option.img = '';
      this.previews.url = '';
      this.dialogVisible = true;
    },
    // 实时预览函数
    realTime(data) {
      // const previews = data
      // const h = 0.5
      // const w = 0.2

      this.previewStyle = {
        width: data.w + "px",
        height: data.h + "px",
        overflow: "hidden",
        margin: "0",
        zoom: 100 / data.w,
      };
      this.previews = data;
    },
    // 上传图片
    getpicture() {
      if (this.previews.url === "") {
        const doms = document.getElementsByClassName("el-message")[0];
        if (doms === undefined) {
          this.$message.warning("请先选择图片！");
        }
        return false;
      }
      
      let that = this;
      // // 获取截图的blob数据
      that.$refs.cropper.getCropBlob(async (res) => {
        const formData = new FormData();
        formData.append("file", res);
        const { data } = await uploadFile(formData);
        if(data && data.code == 200){   
          that.$refs.upload.value = ''
          that.dialogVisible = false;
          that.$emit('imgUpload',data.url);
        }else{
          that.$message.error(data.msg)
        }
      });
    },
    uploadImg(e) {
      // 上传图片
      const file = e.target.files[0];
      const isLt1M = file.size / 1024  < 300
      if (!/\.(jpg|png|JPG|PNG|jpeg|JPEG)$/.test(e.target.value)) {
        this.$message.warning("图片类型必须是jpg,png,jpeg中的一种");
        return false;
      }
      if (!isLt1M) {
        this.$message.error('上传图片大小不能超过 300KB!')
        return false
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.option.img = data;
      };
      // 转化为base64
      //   reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file);
    },
  },
};
</script>

<style scoped lang="scss">
.functionButton {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}
.btn {
  font-size: 12px !important;
}
::v-deep .el-dialog__body {
  padding: 0px 20px 15px !important;
}
.cropper_box {
  .optionBtn {
    margin-bottom: 10px;
  }
  .img-format {
    margin-bottom: 10px;
    color: red;
  }
}

.show-preview div img {
  max-width: none !important;
}

.show-preview {
  margin-left: 6%;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}

.cut_box {
  display: flex;
}

.cut {
  width: 526px;
  height: 300px;
  // margin: 30px auto;
}

.c-item {
  max-width: 526px;
  margin: 10px auto;
  margin-top: 20px;
}

.content {
  margin: auto;
  max-width: 526px;
  // margin-bottom: 100px;
}

.test-button {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
.btnColor {
  color: #ffffff;
  background-color: #1890ff;
  border-color: #1890ff;
}
.btn {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  color: #1f2d3d;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 20px 10px 0px 0px;
  padding: 9px 15px;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  transition: all 0.2s ease;
  text-decoration: none;
  user-select: none;
}

.des {
  line-height: 30px;
}

code.language-html {
  padding: 10px 20px;
  margin: 10px 0px;
  display: block;
  background-color: #333;
  color: #fff;
  overflow-x: auto;
  font-family: Consolas, Monaco, Droid, Sans, Mono, Source, Code, Pro, Menlo,
    Lucida, Sans, Type, Writer, Ubuntu, Mono;
  border-radius: 5px;
  white-space: pre;
}

.show-info {
  margin-bottom: 50px;
}

.show-info h2 {
  line-height: 50px;
}

/*.title, .title:hover, .title-focus, .title:visited {
      color: black;
    }*/

.title {
  display: block;
  text-decoration: none;
  text-align: center;
  line-height: 1.5;
  margin: 20px 0px;
  background-image: -webkit-linear-gradient(
    left,
    #3498db,
    #f47920 10%,
    #d71345 20%,
    #f7acbc 30%,
    #ffd400 40%,
    #3498db 50%,
    #f47920 60%,
    #d71345 70%,
    #f7acbc 80%,
    #ffd400 90%,
    #3498db
  );
  color: transparent;
  -webkit-background-clip: text;
  background-size: 200% 100%;
  animation: slide 5s infinite linear;
  font-size: 40px;
}

.test {
  height: 500px;
}

.c-item {
  display: block;
  user-select: none;
}

@keyframes slide {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
}
</style>
<style lang="scss">
#CropperImage {
  .el-dialog__body {
    padding: 0 30px 20px 30px;
  }
}
</style>
