<!--
  文件描述：
  创建时间：2022/7/27 13:53
  创建人：BDJJB
-->
<template>
  <div class="BadgeInformation">
    <el-popover
      placement="bottom"
      width="350"
      class="whiteClo padding020 handednessStyle"
      trigger="hover">
      <ul class="infinite-list popup" v-infinite-scroll="load" style="padding-left:10px">
        <li v-if="numDataList.length === 0" style="text-align: center;list-style: none;padding: 0;margin: 0">暂无数据</li>
        <li v-else v-for="(item, index) in numDataList" @click="execution(item)" :key="index" :class="item.readTime == null ? 'popup_item' : 'popup_item read'">
          <div class="newName" :title="item.jobName + '-' + item.message">
            {{ item.jobName + "-" + item.message }}
          </div>
          <div class="rightTime">
            {{ $moment(item.completionTime).format("MM-DD HH:mm:ss") }}
          </div>
        </li>
      </ul>
      <div slot="reference">
        <el-badge
          title="消息"
          :value="unreadInfo"
          class="item"
          style="display: block"
          :hidden="unreadInfo === 0 ? true : false">
          <i class="icon el-icon-message-solid"/>
        </el-badge>
      </div>
    </el-popover>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
// 例如：import uploadFile from '@/components/uploadFile/uploadFile'
import {webscoket} from '@/config/modules/net.config'
import {infoStatus} from '@/api/BadgeInformation/index'

export default {
  name: 'BadgeInformation',
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      menuList: [],
      userInfo: {},
      websock: null,
      // id: this.$store.state.login.loginInfo.id,
      id: this.$store.state.user.userId,
      orgId: this.$store.state.user.orgId,
      numDataList: "",//消息列表
      unreadInfo: '',//未读消息数量
      bigIcon: '',//大屏只有省级用户可查看
      menuSetting: {
        menuSettingFlag: 0,
        menuSettingData: []
      }
    }
  },

  computed: {},// 监听属性 类似于data概念
  methods: {
    initWebSocket() {
      //初始化weosocket
      const wsuri = webscoket + `/WebSocket/server/${this.id}`;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      let actions = {test: "12345"};
      this.websocketsend(JSON.stringify(actions));
    },
    websocketonerror() {
      //连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e) {
      //数据接收
      let list = JSON.parse(e.data);
      this.$store.state.newStatus = list.state;
      const redata = list.list;
      this.unreadInfo = list.unreadInfo // 未读消息数量
      this.numDataList = redata;
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      console.log("断开连接", e);
    },
    load() {
      this.count += 2;
    },
    async execution(item) {
      if (item.status == 0) {
        this.$router.push({
          path: item.route,
          query: {status: 1, item: item.param, id: item.id},
        });
      } else {
       const {data} = await infoStatus({id: item.id})
        if(data){
          this.$message({
            offset: 80,
            type: 'error',
            message: item.jobName + "-" + item.message,
            duration: 3000
          })
        }
      }
    },
  },// 方法集合
  watch: {},// 监控data中的数据变化
  created() {
    // this.initWebSocket();
  },// 生命周期 - 创建完成（可以访问当前this实例）
  mounted() {
  },// 生命周期 - 挂载完成（可以访问DOM元素）
  beforeCreate() {
  }, // 生命周期 - 创建之前
  beforeMount() {
  }, // 生命周期 - 挂载之前
  beforeUpdate() {
  }, // 生命周期 - 更新之前
  updated() {
  }, // 生命周期 - 更新之后
  beforeDestroy() {
  }, // 生命周期 - 销毁之前
  destroyed() {
    this.websock.close(); //离开路由之后断开websocket连接
  }, // 生命周期 - 销毁完成
  activated() {
  } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
//@import url(); 引入公共css类

.BadgeInformation {
  margin-left: 20px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  font-size: 16px;


  ul{
    li{
      padding: 0;
      margin: 0;
      list-style: none;
    }

  }
}
.popup {
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow: auto;

  .popup_item {
    line-height: 50px;
    height: 50px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    list-style: none;
    border-bottom: 1px solid #efefef;

    .newName {
      float: left;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 50%;
    }

    .rightTime {
      float: right;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .read {
    color: #b7b9bd;
  }

  .item {
    margin-top: 10px;
    margin-right: 40px;
  }
}
</style>
