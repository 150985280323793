<template>
  <div class="bd-top-tabs">
    <div class="vab-tabs">
    <Collapse />
    <el-tabs
      v-model="tabActive"
      class="vab-tabs-content vab-tabs-content-card"
      type="card"
      @tab-click="handleTabClick"
      @tab-remove="handleTabRemove"
    >
      <el-tab-pane
        v-for="item in visitedRoutes"
        :key="item.path"
        :closable="!isNoClosable(item)"
        :name="item.path"
      >
        <span
          slot="label"
          style="display: inline-block"
        >
          <template v-if="theme.showTabsIcon">
            <svg-icon
              v-if="item.meta && item.meta.icon"
              :icon-class="item.meta.icon"
              :is-custom-svg="item.meta.isCustomSvg"
            />
            <!--  如果没有图标那么取第二级的图标 -->
            <svg-icon v-else :icon-class="item.parentIcon" />
          </template>
          <span>{{ item.meta.title }}</span>
        </span>
      </el-tab-pane>
    </el-tabs>

    <el-dropdown @command="handleCommand" @visible-change="handleVisibleChange">
      <svg-icon icon-class="play-list-add-fill" />
      <template #dropdown>
        <el-dropdown-menu class="tabs-more">
          <el-dropdown-item command="closeOthersTabs">
            <svg-icon icon-class="close-line" />
            <span>
              关闭其他
            </span>
          </el-dropdown-item>
          <el-dropdown-item command="closeLeftTabs">
            <svg-icon icon-class="arrow-left-line" />
            <span>
              关闭左侧
            </span>
          </el-dropdown-item>
          <el-dropdown-item command="closeRightTabs">
            <svg-icon icon-class="arrow-right-line" />
            <span>
              关闭右侧
            </span>
          </el-dropdown-item>
          <el-dropdown-item command="closeAllTabs">
            <svg-icon icon-class="close-line" />
            <span>
              关闭全部
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { handleActivePath, handleTabs } from '@/utils/routes'

export default {
  name: 'TabsPage',
  props: {
    layout: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tabActive: '',
      active: false,
      visible: false,
      top: 0,
      left: 0,
      hoverRoute: null
    }
  },
  computed: {
    ...mapGetters({
      theme: 'settings/theme',
      routes: 'routes/routes',
      visitedRoutes: 'tabs/visitedRoutes'
    })
  },
  watch: {
    $route: {
      handler (route) {
        this.$nextTick(() => {
          this.addTabs(route)
        })
      },
      immediate: true
    },
    visible (value) {
      if (value) document.body.addEventListener('click', this.closeMenu)
      else document.body.removeEventListener('click', this.closeMenu)
    }
  },
  created () {
    this.initNoClosableTabs(this.routes)
  },
  methods: {
    ...mapActions({
      addVisitedRoute: 'tabs/addVisitedRoute',
      delVisitedRoute: 'tabs/delVisitedRoute',
      delOthersVisitedRoutes: 'tabs/delOthersVisitedRoutes',
      delLeftVisitedRoutes: 'tabs/delLeftVisitedRoutes',
      delRightVisitedRoutes: 'tabs/delRightVisitedRoutes',
      delAllVisitedRoutes: 'tabs/delAllVisitedRoutes'
    }),
    handleTabClick (tab) {
      if (this.isActive(tab.name)) this.$baseEventBus.$emit('reload-router-view')
      else this.$router.push(this.visitedRoutes[tab.index])
    },
    handleVisibleChange (val) {
      this.active = val
    },
    initNoClosableTabs (routes) {
      routes.forEach((route) => {
        if (route.meta && route.meta.noClosable) this.addTabs(route)
        if (route.children) this.initNoClosableTabs(route.children)
      })
    },
    /**
     * 添加标签页
     * @param tag route
     * @returns {Promise<void>}
     */
    async addTabs (tag) {
      const tab = handleTabs(tag)
      if (tab) {
        await this.addVisitedRoute(tab)
        this.tabActive = tab.path
      }
    },
    /**
     * 根据原生路径删除标签中的标签
     * @param rawPath 原生路径
     * @returns {Promise<void>}
     */
    async handleTabRemove (rawPath) {
      await this.delVisitedRoute(rawPath)
      if (this.isActive(rawPath)) this.toLastTab()
    },
    handleCommand (command) {
      switch (command) {
        case 'closeOthersTabs':
          this.closeOthersTabs()
          break
        case 'closeLeftTabs':
          this.closeLeftTabs()
          break
        case 'closeRightTabs':
          this.closeRightTabs()
          break
        case 'closeAllTabs':
          this.closeAllTabs()
          break
      }
    },
    /**
     * 删除其他标签页
     * @returns {Promise<void>}
     */
    async closeOthersTabs () {
      if (this.hoverRoute) {
        await this.$router.push(this.hoverRoute)
        await this.delOthersVisitedRoutes(this.hoverRoute.path)
      } else await this.delOthersVisitedRoutes(handleActivePath(this.$route, true))
      await this.closeMenu()
    },
    /**
     * 删除左侧标签页
     * @returns {Promise<void>}
     */
    async closeLeftTabs () {
      if (this.hoverRoute) {
        await this.$router.push(this.hoverRoute)
        await this.delLeftVisitedRoutes(this.hoverRoute.path)
      } else await this.delLeftVisitedRoutes(handleActivePath(this.$route, true))
      await this.closeMenu()
    },
    /**
     * 删除右侧标签页
     * @returns {Promise<void>}
     */
    async closeRightTabs () {
      if (this.hoverRoute) {
        await this.$router.push(this.hoverRoute)
        await this.delRightVisitedRoutes(this.hoverRoute.path)
      } else await this.delRightVisitedRoutes(handleActivePath(this.$route, true))
      await this.closeMenu()
    },
    /**
     * 删除所有标签页
     * @returns {Promise<void>}
     */
    async closeAllTabs () {
      await this.delAllVisitedRoutes()
      this.toLastTab()
      await this.closeMenu()
    },
    /**
     * 跳转最后一个标签页
     */
    toLastTab () {
      const latestView = this.visitedRoutes.slice(-1)[0]
      if (latestView) this.$router.push(latestView)
      else this.$router.push('/')
    },
    isActive (path) {
      return path === handleActivePath(this.$route, true)
    },
    isNoClosable (tag) {
      return tag.meta && tag.meta.noClosable
    },
    closeMenu () {
      this.visible = false
      this.hoverRoute = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .bd-top-tabs {
    padding: 0 20px;
    background: #FFFFFF;
  }
  .vab-tabs {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    min-height: $base-tabs-height;
    padding-right: $base-padding;
    padding-left: $base-padding;
    user-select: none;
    background: $base-color-white;
    border-top: 1px solid #f6f6f6;
    border-radius: 0 0 6px 6px;
    box-shadow: 0px 3px 6px 0px rgba(32 125 255 / 10%); 

    ::v-deep {
      // .coll-fold {
      //   margin-right: $base-margin;
      // }

      [class*='ri'] {
        margin-right: 3px;
      }
    }

    &-content {
      // width: calc(100% - 40px);
      width: calc(100% - 60px);

      &-card {
        height: $base-tag-item-height;

        ::v-deep {
          .el-tabs__nav-next,
          .el-tabs__nav-prev {
            height: $base-tag-item-height;
            line-height: $base-tag-item-height;
          }

          .el-tabs__header {
            border-bottom: 0;

            .el-tabs__nav {
              border: 0;
            }

            .el-tabs__item {
              box-sizing: border-box;
              height: $base-tag-item-height;
              margin-right: 5px;
              line-height: $base-tag-item-height;
              border: 1px solid $base-border-color;
              border-radius: $base-border-radius;
              transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;

              &.is-active {
                color: $base-color-blue;
                background: mix($base-color-white, $base-color-blue, 90%);
                border: 1px solid $base-color-blue;
                outline: none;
              }

              &:hover {
                border: 1px solid $base-color-blue;
              }
            }
          }
        }
      }
    }

    .contextmenu {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }

  }
</style>
